<template>
  <div class="livestream pt-0">
    <div class="height-100 m-auto">
      <!-- this div below loads the live stream embeded code from backend -->
      <div class="iframe_container_container w-100 me-md-3">
        <div class="" v-html="embed"></div>
        <!-- <div style="position: relative; padding-bottom: 40%; height: 0">
          <iframe
            src="https://player.viloud.tv/embed/channel/b8e973fbf0633da8848a7808c522f52b?autoplay=0&volume=1&controls=1&title=1&share=1&open_playlist=0&random=0"
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            "
            frameborder="0"
            allow="autoplay"
            allowfullscreen
          ></iframe>
        </div> -->
      </div>
      <!-- <div
        class="
          sideAds_container
          p-3
          mt-3 mt-md-0
          me-md-
          text-white
          rounded_custom_border
          primary_bg_color
        "
      >
        <sideAds class="" />
      </div> -->
    </div>
  </div>
</template>

<script>
  /*eslint-disable*/
import SideAds from "./SideAds.vue";
import axios from "../config/axios.js";

/*eslint-disable*/
export default {
  components: {
    SideAds,
  },
  data() {
    return {
      count: 1,
      embed: "",
    };
  },
  mounted() {
    // this.$axios.get('/stream/getcode')
    axios
      .get("/stream/getcode")
      .then((res) => {
        let $dis = this;
        $dis.embed = res.data.code;
        console.log($dis.embed);
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>

<style scoped>
@media screen and (min-width: 992px) {
}
</style>