<template>
	<footer class="primary_bg_color pb-2">
		<section class="section_1 py-5 pt-4 pb-3">
			<div class="w_90 m-auto d-md-flex align-items-center">
				<div class="w-25 m-auto">
					<img
						src="../assets/OMEGA_APP.png"
						alt=""
						class="w-100 m-auto"
					/>
				</div>
				<div
					class="secondary_text_color py-2 py-md-5 rounded-end w-7 text-center text-md-start"
				>
					<h2>Connect With Us, Anyplace Anytime..</h2>
					<p class="w-75 m-auto m-md-0">
						OMEGA Gospel TV app allows you to stay connected to
						OMEGA Gospel TV during the week or when you're away on
						the weekend. Check-in, re-watch a message or sign up,
						all from your phone.
					</p>
					<div
						class="d-flex mt-3 app_link_div justify-content-center justify-content-md-start"
					>
						<img
							src="../assets/Download_on_the_App_Store_Badge.jpg"
							class="me-3"
							alt=""
						/>
						<img src="../assets/google-play-badge.jpg" alt="" />
					</div>
				</div>
			</div>
		</section>
		<section class="section_2">
			<div
				class="d-md-flex justify-content-between align-items-center w_90 m-auto h-100"
			>
				<div class="d-none d-md-block">
					<div class="d-flex align-items-center align-items-md-end">
						<img
							src="../assets/omega_logo.png"
							alt="Omega Gospel TV Logo"
						/>
						<p class="copyright ps-2">
							©2022 by OMEGA Gospel TV Network
						</p>
					</div>
				</div>
				<div class="d-lg-flex text-md-end text-center text-md-start">
					<ul
						class="d-md-flex align-items-end pb-0 mb-0 ps-0 pt-2 pt-md-0"
					>
						<li class="list-unstyled pe-2">
							<router-link
								to="/aboutus"
								class="text-decoration-none secondary_text_color"
								>About Us</router-link
							>
						</li>
						<li class="list-unstyled pe-2">
							<router-link
								to="/contactus"
								class="text-decoration-none secondary_text_color"
								>Contact Us</router-link
							>
						</li>
						<li class="list-unstyled pe-lg-3">
							<a
								href=""
								class="text-decoration-none secondary_text_color"
								>Privacy Policy</a
							>
						</li>
					</ul>
					<div class="pt-2 pt-md-0">
						<SocialLinks />
					</div>
				</div>
				<div class="d-md-none">
					<div
						class="d-flex align-items-center align-items-md- justify-content-center"
					>
						<img
							src="../assets/omega_logo.png"
							alt="Omega Gospel TV Logo"
						/>
						<p class="copyright ps-2">
							©2022 by OMEGA Gospel TV Network
						</p>
					</div>
				</div>
			</div>
		</section>
	</footer>
</template>

<script>
import SocialLinks from "./SocialLinks.vue";

export default {
	components: { SocialLinks },
	data() {
		return {};
	},
};
</script>

<style scoped>
.section_1 .app_link_div img {
	width: 115px;
	height: 35;
}
.copyright {
	font-size: 12px !important;
	color: #a8a8a8 !important;
}

@media screen and (min-width: 768px) {
	.section_1 .app_link_div img {
		width: 150px;
		height: 50;
	}
}
</style>
