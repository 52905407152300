<template>
  <AppHeader />
  <router-view />
  <AppFooter />
</template>

<script>
import AppHeader from "./components/AppHeader.vue";
import AppFooter from "./components/AppFooter.vue";

export default {
  components: { AppHeader, AppFooter },
};
</script>

<style>
.w_20 {
  width: 20%;
}
.w_30 {
  width: 30%;
}
.w_40 {
  width: 40%;
}
.w_50 {
  width: 50%;
}
.w_60 {
  width: 60% !important;
}
.w_70 {
  width: 70%;
}
.w_80 {
  width: 80%;
}
.w_90 {
  width: 90%;
}

.h_60 {
  height: 60% !important;
}

.primary_text_color {
  color: #031526;
}
.primary_bg_color {
  background-color: #031526;
}
.secondary_text_color {
  color: #FFFFFF;
}
.secondary_bg_color {
  background-color: #FFFFFF;
}
/* .secondary_text_color {
  color: #d8dad3;
}
.secondary_bg_color {
  background-color: #d8dad3;
} */

.call_to_action_bg_color {
  background-color: #ff3c38;
}
.call_to_action_text_color {
  color: #ff3c38;
}

#app {
  font-family: "Lexend Deca", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* swiper.js Class */
.swiper-pagination-bullet {
  width: 12px !important;
  height: 12px !important;
  background-color: #ffffff !important;
}
.swiper-button-prev,
.swiper-button-next {
  color: #ffffff !important;
  top: 55% !important;
}

/* Customized Scroll Bar */
::-webkit-scrollbar {
  width: 7px;
  height: 5px;
}
::-webkit-scrollbar-button {
  width: 7px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #061727;
  border: 0px none #061727;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
  background: #061727;
}
::-webkit-scrollbar-thumb:active {
  background: #061727;
}
::-webkit-scrollbar-track {
  background: #fff;
  border: 0px none #fff;
  border-radius: 0px;
}
::-webkit-scrollbar-track:hover {
  background: #fff;
}
::-webkit-scrollbar-track:active {
  background: #fff;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

/* General Css */
input:focus {
  outline: none;
  border: none;
}
p {
  margin-bottom: 0 !important;
}
/* .primary_bg_color {
  background-color: #0f0f0f;
} */
/* .secondary_bg_color {
  background-color: #061727;
} */
.width_100 {
  width: 100%;
}
.cursor_pointer {
  cursor: pointer;
}
.rounded_custom_border {
  border-radius: 25px;
}
</style>
