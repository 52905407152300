<template>
  <a
    v-for="link in social_links"
    target="blank"
    :key="link"
    :href="link.a_href"
    :class="link.a_class"
    ><img :src="link.img_src" :alt="link.img_alt" :width="link.img_width"
  /></a>
</template>

<script>
export default {
  data() {
    return {
      social_links: [
        {
          a_href: "https://www.youtube.com/channel/UCuRhnEQ7y4rCsg__95uy3pw/videos",
          a_class: "me-3",
          img_src: require("../assets/youtube.png"),
          img_alt: "youtube Logo",
          img_width: "25",
        },
        {
          a_href:
            "https://web.facebook.com/OmegaGospelTv.truthonair?_rdc=1&_rdr",
          a_class: "me-3",
          img_src: require("../assets/facebook.png"),
          img_alt: "facebook Logo",
          img_width: "10",
        },
        {
          a_href: "https://www.instagram.com/omega_gospel_tv/?igshid=YmMyMTA2M2Y%3D",
          a_class: "me-3",
          img_src: require("../assets/instagram.png"),
          img_alt: "instagram Logo",
          img_width: "25",
        },
        {
          a_href: "https://wa.me/+27782003226",
          a_class: "",
          img_src: require("../assets/WHATSAPP.png"),
          img_alt: "whatsapp Logo",
          img_width: "23",
        },
      ],
    };
  },
};
</script>
