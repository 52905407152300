<template>
  <main class="secondary_bg_color">
    <div class="w_90 m-auto d-lg-flex align-items-center py-4 rounded">
      <div class="w_40 me-4">
        <div class="w-100 text-center pb-4 mt-2">
        <label class="d-block text-danger">{{errmsg}}</label>
        <label class="d-block text-success">{{successMsg}}</label>
        </div>
        <div class="accordion" id="accordionExample">
          <div
            class="accordion-item"
            v-for="(testimony, i) in testimonies"
            :key="testimony"
          >
            <h2 class="accordion-header" :id="`heading${i}`">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                :data-bs-target="`#collapse${i}`"
                aria-expanded="true"
                :aria-controls="`collapse${i}`"
              >
                {{ testimony.name }}
              </button>
            </h2>
            <div
              :id="`collapse${i}`"
              class="accordion-collapse collapse"
              :class="testimony.class"
              :aria-labelledby="`heading${i}`"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <p>
                  {{ testimony.text }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="accordion" id="accordionExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                Accordion Item #1
              </button>
            </h2>
            <div
              id="collapseOne"
              class="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <strong>This is the first item's accordion body.</strong> It is
                shown by default, until the collapse plugin adds the appropriate
                classes that we use to style each element. These classes control
                the overall appearance, as well as the showing and hiding via
                CSS transitions. You can modify any of this with custom CSS or
                overriding our default variables. It's also worth noting that
                just about any HTML can go within the
                <code>.accordion-body</code>, though the transition does limit
                overflow.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                Accordion Item #2
              </button>
            </h2>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <strong>This is the second item's accordion body.</strong> It is
                hidden by default, until the collapse plugin adds the
                appropriate classes that we use to style each element. These
                classes control the overall appearance, as well as the showing
                and hiding via CSS transitions. You can modify any of this with
                custom CSS or overriding our default variables. It's also worth
                noting that just about any HTML can go within the
                <code>.accordion-body</code>, though the transition does limit
                overflow.
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <div class="w_60 shadow-lg py-4 bg-white rounded mt-3 mt-lg-0"  @submit.prevent="submitTestimony">
        <form method="post" action="#">
          <div>
            <h2 class="w_90 m-auto fs-5 mb-0 fw-bold call_to_action_text_color">
              Share Your Story
            </h2>
          </div>
          <div class="w_90 m-auto mt-2">
            <div class="d-md-flex">
              <div class="w_50 me-3">
                <label for="" class="d-block"
                  >First Name
                  <span class="call_to_action_text_color">*</span></label
                >
                <input type="text" v-model="fname" class="w-100 rounded ps-2" />
              </div>
              <div class="w_50">
                <label for="" class="d-block"
                  >Last Name
                  <span class="call_to_action_text_color">*</span></label
                >
                <input v-model="lname" type="text" class="w-100 rounded ps-2" />
              </div>
            </div>
          </div>
          <div class="w_90 m-auto mt-3">
            <div class="d-md-flex">
              <div class="w_50 me-3">
                <label for="" class="d-block"
                  >Email Address
                  <span class="call_to_action_text_color">*</span></label
                >
                <input type="email" v-model="email" class="w-100 rounded ps-2" />
              </div>
              <div class="w_50">
                <label for="" class="d-block"
                  >Phone Number
                  <span class="call_to_action_text_color">*</span></label
                >
                <input v-model="phone" type="number" class="w-100 rounded ps-2" />
              </div>
            </div>
          </div>
          <div class="w_90 m-auto mt-2">
            <label for="" class="d-block"
                  >Country <span class="call_to_action_text_color">*</span></label
                >
            <div class="d-md-flex">
              <p class="w_10 px-2 py-2">
                <span :class="emoji"></span>
              </p>
              <div class="w_50 me-3">
                  <select class="form-control w_40" name="country" v-model="selectedCountry">
                    <option class="row" v-for="country in countries" :key="country.id" :value="country">
                       <span> {{country.name}}</span>
                    </option>
                  </select>
              </div>
            </div>
          </div>
          <div class="w_90 m-auto mt-2">
              <div class="d-md-flex">
                <div class="w_50 me-3">
                  <label for="" class="d-block"
                    > State/Province<span class="call_to_action_text_color">*</span></label
                  >
                  <select class="form-control w_40" name="country" v-model="selectedState">
                    <option class="row" v-for="state in states" :key="state.id" :value="state">
                       <span> {{state.name}}</span>
                    </option>
                  </select>
                </div>
                <div class="w_50 me-3">
                  <label for="" class="d-block"
                    >City <span class="call_to_action_text_color">*</span></label
                  >
                  <select class="form-control w_40" name="country" v-model="selectedCity">
                    <option class="row" v-for="city in cities" :key="city.id" :value="city">
                       <span> {{city.name}}</span>
                    </option>
                  </select>
                </div>
              </div>
          </div>
      
          <div class="w_90 m-auto mt-2">
            <label for="" class="d-block"
              >Share Your Testimony
              <span class="call_to_action_text_color">*</span></label
            >
            <textarea
              v-model="testimony"
              cols="30"
              rows="4"
              class="w-100 mt-1 rounded ps-2"
            ></textarea>
          </div>
          <div class="w_90 m-auto mt-2">
            <vue-recaptcha sitekey="6LfNvB8jAAAAAJ5sBkFcQCNVHfm8fXz9eeW5Xbyk"></vue-recaptcha>
          </div>
          <div class="w_90 m-auto mt-2">
            <button
              class="
                btn
                call_to_action_bg_color
                w-100
                mt-2
                m-auto
                secondary_text_color
              "
              type="submit"
              :disabled="btnActive"
            >
            <span>{{btnValue}}</span>
            </button>
          </div>

        </form>
      </div>
      
    </div>
  </main>
</template>

<script>
import axios from "../config/axios.js";
import { VueRecaptcha } from 'vue-recaptcha';
export default {
  components: {VueRecaptcha},
  data() {
    return {
      fname: '',
      lname: '',
      phone: '',
      email: '',
      testimony: '',
      testimonies: [],
      selectedCountry:null,
      selectedState:null,
      selectedCity:null,
      countries: [],
      states: [],
      cities: [],
      emoji: null,
      errmsg: '',
      successMsg: '',
      btnActive: false,
      btnValue:'Submit',
    };
  },
  watch:{
    selectedCountry(value){
      // console.log(value.class);
      this.getStates(value.id);

      this.emoji = value.class;
    },
    selectedState(value){
      this.getCities(value.id); 
    },
  },
  methods: {
    getCountries(arr){
        let country =  arr.map((item, index)=>(
          console.log(index)
          // this.countries = {id:item.id, name:item.name, emoji:item.emoji, phonecode:item.phonecode}
      ));

      return this.countries = country;
    },
    getStates(id){
      axios.get('/states/'+id).then(res => {
        let arr =  res.data;
        console.log(arr);
        
        let result = arr.map(item=>(
          {name:item.name, id:item.id,}
          ));

        return this.states = result;
      })
    },
    getCities(id){
      axios.get('/cities/'+id).then(res => {
        let arr =  res.data;
        let result = arr.map(item=>(
          {name:item.name, id:item.id,}
          ));
        return this.cities = result;
      })
    },
    submitTestimony(){
      let formData = new FormData();
      let selectedCountry = this.selectedCountry.id;
      let selectedState = this.selectedState.id;
      let selectedCity = this.selectedCity.id;

      this.btnValue = 'sending...';
      this.btnActive = true;
      
      formData.append("firstname", this.fname);
      formData.append("lastname", this.lname);
      formData.append("email", this.email);
      formData.append("phone", this.phone);
      formData.append("testimony", this.testimony);
      formData.append("country", selectedCountry);
      formData.append("state", selectedState);
      formData.append("city", selectedCity);

      axios.post('/testimony/send', formData).then(res=>{
        console.log(res.data);
        this.btnValue = 'Submit';
            this.btnActive = false;
            this.successMsg = 'Your Testimony has been sent successfully';
      }).catch(err => {
        console.log(err);
        this.btnValue = 'Submit';
            this.btnActive = false;
            this.errmsg = 'Testimony failed to send, Please try again';
      });
    }
  },
  mounted(){
    axios
      .get("/testimony/all")
      .then((res) => {
        let arr = res.data.data;
        let result = arr.map(
          item =>(
              { name: item.country, text: item.testimony, class:'', testifier:item.firstname }
          ));
        this.testimonies = result;
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });

      axios.get('/countries')
      .then( res=>{
        let arr = res.data;
        console.log(arr);
        let country =  arr.map(item =>(
              // console.log(item);
          {id:item.id, name:item.name, emoji:item.emoji, phoneCode:item.phonecode, 
            class:`fi fi-${item.iso2.toLowerCase()}`}
        )
      );
       this.countries = country;
      }).catch(err => {
        console.log(err);
      });
  }
}
</script>

<style scoped>
.w_40,
.w_50,
.w_60 {
  width: 100% !important;
}

input,
textarea {
  border: 1px solid #d8dad3;
}
input:focus,
textarea:focus {
  outline: none;
  border: 1px solid #ff3c38;
}

@media screen and (min-width: 992px) {
  .w_40 {
    width: 40%;
  }
  .w_50 {
    width: 50%;
  }
  .w_60 {
    width: 60%;
  }
}
</style>