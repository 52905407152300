/*eslint-disable*/
"use strict";

import Vue from "vue";
import axios from "axios";


// axios.defaults.baseURL = process.env.baseURl || process.env.apiUrl || '';

axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";

axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.common["app_key"] = "DVOBNAJU";
axios.defaults.headers.common["token"] = "ewbj7K9jWCpn3NEfv9hm";

console.log(process.env.VUE_APP_ROOT_API)

let config = {
  baseURL: process.env.VUE_APP_ROOT_API || "http://127.0.0.1:8000/api",

  withCredentials: false, // Check cross-site Access-Control
};

const _axios = axios.create(config);

// _axios.interceptors.request.use(
//   function (config) {
//     // Do something before request is sent
//     return config;
//   },
//   function (error) {
//     // Do something with request error
//     return Promise.reject(error);
//   }
// );
// // Add a response interceptor
// _axios.interceptors.response.use(
//   function (response) {
//     // Do something with response data
//     return response;
//   },
//   function (error) {
//     // Do something with response error
//     if (error.response.status == 401) {
//        ;
//       if (error.response.data.message == "Unauthenticated.") {
//         Router.push({ name: "AccessControl" });
//       }
//       return Promise.reject(error);
//     }
//     return;
//   }
// );


// window.axios = _axios;

// let myAxiosPlugin = {};
// myAxiosPlugin.install = (Vue, options) => {
//   Vue.axios = _axios;
  // Object.defineProperties(Vue.prototype, {
  //   axios: {
  //     get() {
  //       return _axios;
  //     },
  //   },
  //   $axios: {
  //     get() {
  //       return _axios;
  //     },
  //   },
  //   axios: {
  //     post() {
  //       return _axios;
  //     },
  //   },
  //   axios: {
  //     put() {
  //       return _axios;
  //     },
  //   },
  //   axios: {
  //     delete() {
  //       return _axios;
  //     },
  //   },
  // });
// };

// Vue.use(myAxiosPlugin);

export default _axios;
