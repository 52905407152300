<template>
  <main class="secondary_bg_color">
    <section class="">
      <liveStreamPageVue />
    </section>
    <section class="primary_bg_color secondary_text_color w_90 m-auto my-4">
      <div class="
          d-flex
          flex-column
          align-items-center
          justify-content-center
          text-center
          h-100
          custom_linear_gradient_b
          rounded_custom_border
          shadow-lg
          px-2
          py-5
        ">
        <p class="custon_text_color">
          We are dedicated to spreading the
          <span class="fw-bold">TRUTH</span> of the
          <span class="fw-bold">Gospel of our Lord Jesus Christ</span> in all
          the nations of the world.
        </p>
        <a href="https://omegagospel.org" target="blank"
          class="custon_text_color link text-decoration-none pb-2 pt-2">www.omegagospel.org</a>
        <router-link to="/contact-us" class="
            btn
            text-white
            subscribe_btn
            call_to_action_bg_color
            text-black
            rounded-pill
            px-4
            fw-bold
          ">
          Contact Us
        </router-link>
      </div>
    </section>
  </main>
</template>

<script>
import liveStreamPageVue from "../components/LiveStreamScreen.vue";
export default {
  data() {
    return {};
  },
  components: {
    liveStreamPageVue,
  },
};
</script>
  
<style scoped>

</style>
