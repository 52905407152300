<template>
  <header class="primary_bg_color py-2 sticky-top">
    <nav class="navbar navbar-expand-lg w_90 m-auto h-100">
      <div class="container-fluid px-0">
        <div class="w_20">
          <router-link class="logo text-decoration-none" to="/">
            <!--! Link for the image https://static.wixstatic.com/media/d2ee5e_c10dab52890b4a428285b81decc2c7ba~mv2.png/v1/fill/w_45,h_46,al_c,q_95,enc_auto/LOGO%20PNG_edited.png -->
            <img src="../assets/omega_logo.png" alt="Omega Gospel TV Logo" />
          </router-link>
        </div>
        <button
          class="navbar-toggler bg-light"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          class="collapse navbar-collapse w_80 justify-content-end"
          id="navbarNavAltMarkup"
        >
          <ul class="navbar-nav w_90 d-flex justify-content-around m-auto mt-3der">
            <li class="nav-item" v-for="link in nav_links" :key="link">
              <router-link
                :class="link.class"
                :to="link.to"
                :aria-disabled="true"
                >{{ link.text }}</router-link
              >
            </li>
            <li class="nav-item dropdown">
              <a
                class="
                  nav-link
                  text-white
                  dropdown-toggle
                  secondary_text_color
                  text-center
                "
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Connect With Us
              </a>
              <ul class="dropdown-menu primary_bg_color">
                <li>
                  <router-link
                    to="/testimony"
                    class="dropdown-item secondary_text_color text-center"
                    >Testimonies</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/contact-us"
                    class="dropdown-item secondary_text_color text-center"
                    >Contact Us</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/salvation-prayer"
                    class="dropdown-item secondary_text_color text-center"
                    >Salvation Prayer</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/devotional"
                    class="dropdown-item secondary_text_color text-center"
                    >Devotional</router-link
                  >
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <router-link
                to="/give"
                class="nav-link px- secondary_text_color text-center border"
                >Give</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  data() {
    return {
      div: document.createElement("div"),
      nav_links: [
        {
          class: "nav-link px- secondary_text_color text-center",
          to: "/home",
          text: "Home",
        },
        {
          class: "nav-link px- secondary_text_color text-center",
          to: "/",
          text: "Live Stream",
        },
        {
          class: "nav-link px- secondary_text_color text-center",
          to: "/community-of-ministers",
          text: "Our Community of Ministers",
        },
        {
          class: "nav-link px- secondary_text_color text-center",
          to: "/on-demand",
          text: "On Demand",
        },
        {
          class: "nav-link px- secondary_text_color text-center",
          to: "/vision-mission",
          text: "Vision & Mission",
        },
        {
          class: "nav-link px- secondary_text_color text-center",
          to: "/about-us",
          text: "About Us",
        },
      ],
      dropDownLinks: [
        {
          class: "nav-link py-2 secondary_text_color text-center",
          to: "/testimony",
          text: "Testimonies",
        },
        {
          class: "nav-link py-2 secondary_text_color text-center",
          to: "/contact-us",
          text: "Contact Us",
        },
        {
          class: "nav-link py-2 secondary_text_color text-center",
          to: "/salvation-prayer",
          text: "Salvation Prayer",
        },
        {
          class: "nav-link py-2 secondary_text_color text-center",
          to: "devotional",
          text: "Devotional",
        },
      ],
    };
  },
  // methods: {
  //   check: function (id) {
  //     document.getElementById(id).classList.add("call_to_action_bg_color");
  //   },
  //   showDropDown: function (text) {
  //     // document.getElementsByTagName("a")[7].disabled = true;
  //     let parent = document.getElementsByClassName('navbar-nav justify-content-between ps-lg- width_100')[0];
  //     let pTag = document.createElement('p');
  //     let link = document.getElementsByTagName("a")[7];
  //     pTag.innerText = "Connect With Us";
  //     pTag.style.fontSize = '12px'
  //     pTag.classList.add('secondary_text_color', 'px-3', 'text-center', 'nav-link', 'mine')

  //     // parent.replaceChild(pTag, link);

  //     console.log('working')
  //     if (text == "Contact Us") {
  //       console.log(this.div);
  //       this.div.style.width = "100px";
  //       this.div.style.height = "200px";
  //       this.div.style.backgroundColor = "white";
  //       this.div.style.position = "absolute";
  //       this.div.setAttribute("id", "drop_down");

  //       const links = [];
  //       for (let i = 0; i < 4; i++) {
  //         links.push(document.createElement("router-link"));
  //       }

  //       console.log(links);

  //       for (let i = 0; i < 4; i++) {
  //         this.div.appendChild(links[i]);
  //       }

  //       for (let i = 0; i < 4; i++) {
  //         links[i].innerText = "hdsh";
  //       }

  //       document.getElementsByTagName("a")[7].appendChild(this.div);
  //     }
  //   },
  //   hideDropDown: function () {
  //   },
  // },
};
</script>

<style scoped>
.logo .router-link-active {
  background-color: inherit !important;
}
.dropdown_link {
  width: 200px;
}
.router-link-active {
  background-color: #ff3c38;
  color: white !important;
}
.router-link-active:hover {
  background-color: inherit;
  border: 1px solid white;
}
.logo {
  background-color: inherit;
}
.logo:hover {
  border: none;
}
.nav-link:hover {
  color: #ff3c38;
}
/* .nav-link:focus {
  background-color: #ff3c38;
  transition: 0.5s;
  color: #d8dad3;
} */
/* .secondary_text_color:checked {
  background-color: #031526;
} */
@media screen and (min-width: 1024px) {
  a {
    font-size: 13px;
  }
}
@media screen and (min-width: 1440px) {
  a {
    font-size: 16px;
  }
}
</style>