<template>
  <main class="secondary_bg_color">
    <div
      class="
        w_90
        m-auto
        d-lg-flex
        align-items-center
        justify-content-between
        pb-
        pb-4
      "
    >
      <div class="w_50 pt-3 pt-lg-0" style="height: ">
        <!-- <img
          class="w-100 h-100 rounded shadow-lg"
          src="../assets/prayer.jpg"
          alt="prayer"
          height="300"
        /> -->
        <!-- <SingleImgSlider class="rounded shadow-lg" /> -->
        <h2>Salvation Prayer</h2>
        <p style="text-align: justify;">
          <strong>Lord Jesus</strong>, I thank You for giving Your life for me.
          I confess that I am a sinner, I cannot save myself and I need Your
          forgiveness. I confess with my mouth that You are my Lord and Saviour,
          You died on the cross for my sins, and I believe in my heart that You
          rose again for my justification. I open my heart to you today, and I
          receive Your gift of salvation with faith. I receive Your gift of
          eternal life with joy today. Thank You for saving me. Amen.
        </p>
      </div>
      <div class="w_40 bg-white rounded mt- shadow-lg mt-4">
        <div class="w_90 m-auto">
          <label for="" class="d-block text-cente pt-3"
            >Share Your Prayer Request
            <span class="call_to_action_text_color">*</span></label
          >
          <textarea
            name=""
            id=""
            cols="30"
            rows="6"
            class="w-100 mt-1"
          ></textarea>
        </div>
        <div class="w_90 m-auto mt-2">
          <div class="d-md-flex">
            <div class="w_50 me-3">
              <label for="" class="d-block"
                >First Name
                <span class="call_to_action_text_color">*</span></label
              >
              <input type="text" class="w-100" />
            </div>
            <div class="w_50">
              <label for="" class="d-block"
                >Last Name
                <span class="call_to_action_text_color">*</span></label
              >
              <input type="text" class="w-100" />
            </div>
          </div>
        </div>
        <div class="w_90 m-auto mt-2">
          <label for="" class="d-block"
            >Email Address
            <span class="call_to_action_text_color">*</span></label
          >
          <input type="text" class="w-100" />
        </div>
        <div class="w_90 m-auto mt-2 pb-4">
          <button
            class="
              btn
              call_to_action_bg_color
              w-100
              mt-2
              m-auto
              secondary_text_color
            "
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
// import SingleImgSlider from "../components/SingleImgSlider.vue";

export default {
  components: {
    // SingleImgSlider,
  },
};
</script>

<style scoped>
.w_40,
.w_50 {
  width: 100%;
}

input,
textarea {
  border: 1px solid #d8dad3;
}
input:focus,
textarea:focus {
  outline: 1px solid #ff3c38;
}

@media screen and (min-width: 992px) {
  .w_40 {
    width: 40%;
  }
  .w_50 {
    width: 50%;
  }
}
</style>