<template>
  <swiper :slidesPerView="5" :spaceBetween="20" :navigation="true" :grabCursor="true" :modules="modules"
    class="mySwiper">
    <swiper-slide style="background-color: inherit" v-for="image in images" :key="image">
      <div><img class="rounded_custom_border" :src="image.url" :alt="image.alt" /></div>
    </swiper-slide>
  </swiper>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper";

export default {
  data() {
    return {
      images: [
        {
          url: require("../assets/Community_of_ministers/4_life_oasis.jpg"),
          alt: "image 1",
          class: "show img mx-md-3",
          link: "https://www.youtube.com/watch?v=DiT3IXedhus",
        },
        {
          url: require("../assets/Community_of_ministers/9_Kings_Outreach_ministries.jpg"),
          alt: "image 2",
          class: "hide img mx-md-3",
          link: "https://www.youtube.com/watch?v=MXx_fclQ8Uw",
        },
        {
          url: require("../assets/Community_of_ministers/17_New_Wine_Apostolic_Ministry.jpg"),
          alt: "image 3",
          class: "hide img mx-md-3",
          link: "https://www.youtube.com/watch?v=VwHbxHWvySI",
        },
        {
          url: require("../assets/Community_of_ministers/23_THE_HIDDEN_MANNA.jpg"),
          alt: "image 4",
          class: "hide img mx-md-3",
          link: "https://www.youtube.com/watch?v=l80Qte9HmSM",
        },
        {
          url: require("../assets/Community_of_ministers/5_Rev_Biyi_Ajala.jpg"),
          alt: "image 5",
          class: "hide img mx-md-3",
          link: "https://www.youtube.com/watch?v=E9cQTL6EXm8",
        },
        {
          url: require("../assets/Community_of_ministers/24_God's_House_of_Refuge.jpg"),
          alt: "image 6",
          class: "hide img mx-md-3",
          link: "https://www.youtube.com/watch?v=eSK4LoCsWXQ",
        },
        {
          url: require("../assets/Community_of_ministers/12_Ps._Sunday.jpg"),
          alt: "image 7",
          class: "hide img mx-md-3",
          link: "https://www.youtube.com/watch?v=YYKA9KlHOEw",
        },
        {
          url: require("../assets/Community_of_ministers/2_The_700_club.jpg"),
          alt: "image 8",
          class: "hide img mx-md-3",
          link: "https://www.youtube.com/watch?v=WuvKxxav2pI",
        },
      ],
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Navigation],
    };
  },
};
</script>

<style scoped>
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
