<template>
  <div
    class="
      d-flex
      flex-column
      align-items-center
      justify-content-center
      text-center
      h-100
      custom_linear_gradient_b
      rounded_custom_border
      shadow-lg
      px-2
    "
  >
    <img
      class="pb-2"
      width=""
      src="https://static.wixstatic.com/media/d2ee5e_c10dab52890b4a428285b81decc2c7ba~mv2.png/v1/fill/w_45,h_46,al_c,q_95,enc_auto/LOGO%20PNG_edited.png"
      alt=""
    />
    <h2>Download and enjoy our app</h2>
    <p class="custon_text_color pb-2 pb-md-5">
      We are dedicated to spreading the
      <span class="fw-bold">TRUTH</span> of the
      <span class="fw-bold">Gospel of our Lord Jesus Christ</span> in all the
      nations of the world.
    </p>
    <a
      href="https://omegagospel.org"
      target="blank"
      class="custon_text_color link text-decoration-none pb-2"
      >www.omegagospel.org</a
    >
    <a
      href="https://www.youtube.com/channel/UCuRhnEQ7y4rCsg__95uy3pw"
      target="blank"
      class="
        btn
        text-white
        subscribe_btn
        call_to_action_bg_color
        text-black
        rounded-pill
        px-4
        fw-bold
      "
    >
      Subscribe Now
    </a>
    <div class="pt-3">
      <!-- <a
        v-for="link in social_links"
        :key="link"
        :href="link.a_href"
        :class="link.a_class"
        ><img :src="link.img_src" :alt="link.img_alt" :width="link.img_width"
      /></a> -->
      <SocialLinks />
    </div>
  </div>
</template>

<script>
import SocialLinks from "./SocialLinks.vue";

export default {
  components: { SocialLinks },
  data() {
    return {};
  },
};
</script>

<style scoped>
.custon_text_color {
  color: #5db7d4;
}
.custon_background_color {
  background-color: #5db7d4;
}
.custom_linear_gradient_bg {
  background: rgb(15, 15, 15);
  background: linear-gradient(
    0deg,
    rgba(15, 15, 15, 1) 0%,
    rgba(15, 44, 73, 0.8225665266106442) 100%
  );
}
p {
  font-size: 11px;
}
p span,
.subscribe_btn {
  font-size: 12px;
}
.link {
  font-size: 14px;
}
@media screen and (min-width: 992px) {
  p {
    font-size: 13px;
  }
  p span {
    font-size: 16px;
  }
  .link {
    font-size: 16px;
  }
}
</style>
