<template>
  <main>
    <div class="secondary_bg_color">
      <div class="d-md-flex align-items-center w_90 m-auto py-5">
        <div class="w_60">
          <h2 class="mb-0 call_to_action_text_color">About Us</h2>
          <p class="w_80">
            <span class="fw-bold"> OMEGA Gospel TV Network</span> is a
            Christian-based broadcast platform that continuously airs a unique
            range of high-quality Christian programs around the world, 24/7.
          </p>
        </div>
        <div class="w_40 d-none d-md-block">
          <img
            src="../assets/Community_of_ministers/1_PRESENT_TRUTH.jpg"
            alt=""
            class="position-relative w-100 h-100 rounded shadow-lg"
            style=""
          />
        </div>
      </div>
    </div>
  </main>
</template>

<style scoped>
.w_60, .w_80 {
  width: 100% !important;
}
@media screen and (min-width: 768px) {
  .w_60 {
    width: 60% !important;
  }
  .w_80 {
    width: 80% !important;
  }
}
@media screen and (min-width: 992px) {
}
</style>