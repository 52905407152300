<template>
  <main>
    <section class="secondary_bg_color py-4">
      <div class="w_90 m-auto d-md-flex align-items-center">
        <div class="w_40 me-4">
          <h2 class="mb-0">Our Vision</h2>
          <hr class="my-1" />
          <p>
            We have the vision to bring these various great ministries to the
            fore world of evangelism and discipleship, by creating a platform
            for these ministers to minister to the world. We have a passion to
            see the undiluted and compromised TRUTH of the gospel of our Lord
            Jesus Christ, proclaimed with the aim of building up the individual,
            along with marriages and families in Africa and the rest of the
            world. We aim to achieve this by bringing a refreshing renewal to
            the world through partnership with our various ministers and
            Christian producers who will air their programs on our platform, as
            well as our viewers, whose support we greatly value.
          </p>
        </div>
        <div class="w_60 pt-3 pt-md-0">
          <!--! Link to Previous Video https://video.wixstatic.com/video/11062b_2ccb88c1c6de4151b51879d6c90fca9d/1080p/mp4/file.mp4 -->
          <!-- <video
            class="video w-100 h-100 rounded"
            role="presentation"
            crossorigin="anonymous"
            playsinline="true"
            preload="auto"
            muted
            loop="true"
            tabindex="-1"
            autoplay="true"
            src="../assets/video2.mp4"
            style=""
          ></video> -->
          <img
            src="../assets/vision&mission.jpeg"
            alt=""
            class="w-100 rounded"
          />
        </div>
      </div>
    </section>

    <section class="primary_bg_color secondary_text_color py-3">
      <div class="w_90 m-auto">
        <p class="text-center text-md-end">
          “For the earth shall be full of the knowledge of the LORD, as the
          waters cover the sea (Isaiah 11:9b)”
        </p>
      </div>
    </section>

    <section class="secondary_bg_color py-4">
      <div class="w_90 m-auto d-md-flex align-items-center">
        <div class="w_60">
          <!--! Link to the image https://static.wixstatic.com/media/4babaf95317033e8bc0f84b68aed5718.jpg/v1/fill/w_983,h_381,al_c,q_90,enc_auto/Desert%20Highway.jpg -->
          <img
            src="../assets/spreading-the-fragrance.jpeg"
            alt=""
            class="w-100 rounded"
          />
        </div>
        <div class="w_40 ms-md-4">
          <h2 class="mb-0 pt-3 pt-md-0">Our Mission</h2>
          <hr class="my-1" />
          <p class="">
            Our desire is to see the knowledge of Christ fill the earth,
            especially in Africa, through programs delivered primarily by
            African ministers in Africa and the Diaspora, and by other
            International ministers, who are being used greatly by God to spread
            the TRUTH of the gospel of Christ in their different localities, and
            who want to reach out to, and sow back into Africa with the gospel.
          </p>
        </div>
      </div>
    </section>
  </main>
</template>

<style scoped>
.w_40,
.w_60 {
  width: 100% !important;
}
.video {
  object-fit: cover;
  object-position: center center;
}
p {
  text-align: justify;
}

@media screen and (min-width: 992px) {
  .w_40 {
    width: 40%;
  }
  .w_60 {
    width: 60%;
  }
}
</style>