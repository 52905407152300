import { createRouter, createWebHistory } from "vue-router";
import homePage from "../views/homePage.vue";
import liveStream from "../views/liveStreamPage.vue";
import communityofMinisters from "../views/communityofMinisters.vue";
import onDemandPage from "../views/onDemandPage.vue";
import visionMissionPage from "../views/visionMissionPage.vue";
import aboutUs from "../views/aboutUs.vue";
import contactUs from "../views/contactUs.vue";
import salvationPrayer from "../views/salvationPrayers.vue";
import testimonyPage from "../views/testimonyPage.vue";
import donationPage from "../views/donationPage.vue";
import devotionalPage from "../views/devotionalPage.vue";

const routes = [
  {
    path: "/home",
    name: "home",
    component: homePage,
  },
  {
    path: "/",
    name: "liveStream",
    component: liveStream,
  },
  {
    path: "/community-of-ministers",
    name: "communityOfMinisters",
    component: communityofMinisters,
  },
  {
    path: "/on-demand",
    name: "onDemand",
    component: onDemandPage,
  },
  {
    path: "/vision-mission",
    name: "visionMission",
    component: visionMissionPage,
  },
  {
    path: "/about-us",
    name: "aboutus",
    component: aboutUs,
  },
  {
    path: "/contact-us",
    name: "contactus",
    component: contactUs,
  },
  {
    path: "/salvation-prayer",
    name: "salvationPrayer",
    component: salvationPrayer,
  },
  {
    path: "/testimony",
    name: "testimony",
    component: testimonyPage,
  },
  {
    path: "/give",
    name: "donation",
    component: donationPage,
  },
  {
    path: "/devotional",
    name: "devotionalPage",
    component: devotionalPage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
