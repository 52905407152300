<template>
  <!--! Force Push git push -f origin updated-site-01 -->
  <main>
    <section class="section_1">
      <div>
        <video
          id=""
          class="w-100 h-100"
          preload=""
          muted=""
          loop=""
          tabindex="-1"
          autoplay=""
          src="https://video.wixstatic.com/video/d2ee5e_d4ce78f5a0ac4cb390d8af095c25a993/1080p/mp4/file.mp4"
        ></video>
      </div>
    </section>
    <section class="section_2 secondary_bg_color py-3 py-md-4">
      <div class="w_90 m-auto">
        <h2 class="fw-bold mb-0 primary_text_color">OMEGA GOSPEL TV NETWORK</h2>
        <p class="pb-2 pb-md-3 primary_text_color">On Demand</p>
        <MultipleImgSlider class="d-none d-md-block" />
        <SingleImgSlider class="d-md-none rounded" />
      </div>
    </section>
    <section
      class="section_3 secondary_bg_color pt-md-0 pt-lg-3 py-3 pb-4 pb-md-4"
    >
      <div class="w_90 m-auto d-xl-flex justify-content-between">
        <div class="w_40 d-flex flex-column justify-content-center pe-xl-4">
          <p class="fs-6">
            OMEGA Gospel TV Network is a Christian-based broadcast platform that
            continuously airs a unique range of high-quality Christian programs
            around the world, 24/7.
          </p>
          <router-link
            to="/testimony"
            href="#"
            class="text-decoration-none mt-2"
            >View Testimonies
            <img
              class=""
              width="10"
              src="../assets/right-long-solid.svg"
              alt=""
          /></router-link>
        </div>
        <div class="w_60 testimony pt-3 pt-lg-0">
          <h2 class="primary_text_color fw-bolder text-cente">Testimonies</h2>
          <div class="d-md-flex justify-content-between custom_height">
            <div
              v-for="testimony in testimonies.slice(0, 3)"
              :key="testimony"
              :class="testimony.class"
              class="
                secondary_bg_color
                p-2
                mb-3 mb-md-0
                rounded
                bg-white
                shadow
                w_30
              "
            >
              <p class="h-75 d-md-flex align-items-center">
                {{ testimony.text }}
              </p>
              <hr class="my-1 my-lg-0" />
              <p class="text-secondary h-25 d-flex align-items-center">
                {{ testimony.name }}
              </p>
            </div>
            <!-- <div
              class="
                secondary_bg_color
                p-2
                mb-3 mb-md-0
                me-md-3
                rounded
                bg-white
                shadow
              "
            >
              <h3 class="fs-6 fw-bolder mb-0">All Things Work Together</h3>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit...</p>

              <hr class="me-2 my-0 mt-3 mb-2" />
              <p class="text-secondary">October 26, 2022</p>
            </div>
            <div
              class="
                secondary_bg_color
                p-2
                mb-3 mb-md-0
                rounded
                bg-white
                shadow
              "
            >
              <h3 class="fs-6 fw-bolder mb-0">All Things Work Together</h3>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit...</p>
              <hr class="me-2 my-0 mt-3 mb-2" />
              <p class="text-secondary">October 26, 2022</p>
            </div> -->
          </div>
          <div class="d-md-flex justify-content-between mt-3 custom_height">
            <div
              v-for="testimony in testimonies.slice(3)"
              :key="testimony"
              :class="testimony.class"
              class="
                secondary_bg_color
                p-2
                me-md-
                mb-3 mb-md-0
                rounded
                bg-white
                shadow
                w_30
              "
            >
              <p class="h-75 d-md-flex align-items-center">
                {{ testimony.text }}
              </p>
              <hr class="my-1 my-lg-0" />
              <p class="text-secondary h-25 d-flex align-items-center">
                {{ testimony.name }}
              </p>
            </div>
            <!-- <div
              class="
                secondary_bg_color
                p-2
                pe-0
                me-md-3
                mb-3 mb-md-0
                rounded
                bg-white
                shadow
              "
            >
              <h3 class="fs-6 fw-bolder mb-0">All Things Work Together</h3>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit...</p>

              <hr class="me-2 my-0 mt-3 mb-2" />
              <p class="text-secondary">October 26, 2022</p>
            </div>
            <div class="secondary_bg_color p-2 rounded bg-white shadow">
              <h3 class="fs-6 fw-bolder mb-0">All Things Work Together</h3>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit...</p>
              <hr class="me-2 my-0 mt-3 mb-2" />
              <p class="text-secondary">October 26, 2022</p>
            </div> -->
          </div>
        </div>
      </div>
    </section>
    <section class="section_4 secondary_bg_color pb-1 pb-md-">
      <div class="w_90 m-auto d-md-flex text-center pb-4">
        <div class="w_50 me-4 shadow-lg border rounded img_container_1">
          <router-link
            to="/salvation-prayer"
            class="
              fw-bold
              fs-1
              secondary_text_color
              text-decoration-none
              py-2
              px-3
              rounded
              d-flex
              align-items-center
              justify-content-center
              h-100
              w-100
            "
            >Salvation Prayer</router-link
          >
        </div>
        <div class="w_50 shadow-lg border rounded img_container_2">
          <router-link
            to="/devotional"
            class="
              fw-bold
              fs-1
              secondary_text_color
              text-decoration-none
              py-2
              px-3
              rounded
              d-flex
              align-items-center
              justify-content-center
              h-100
              w-100
            "
            >Devotional</router-link
          >
        </div>
      </div>
    </section>
    <section class="section_5 secondary_bg_color pb-3 pb-md-5">
      <div
        class="
          w_90
          m-auto
          primary_bg_color
          secondary_text_color
          text-center
          py-4 py-md-5
          px-3 px-lg-0
          rounded
        "
      >
        <h2 class="mb-0">Truth On Air</h2>
        <p class="pt-2 w_70 m-auto">
          We are on a search for ministers who speak the TRUTH in love, and who
          have a continuing passion to see the true and uncompromised Gospel of
          our Lord and Savior Jesus Christ spread and taught in Africa, and
          indeed, the entire world.
        </p>
        <p class="pt-2">
          If you wish to air your ministry programming with us, please contact
          us.
        </p>
        <router-link
          to="/contact-us"
          class="btn call_to_action_bg_color secondary_text_color mt-3 w-50"
          >Contact Us</router-link
        >
      </div>
    </section>
  </main>
</template>

<script>
import axios from "../config/axios.js";
import SingleImgSlider from "../components/SingleImgSlider.vue";
import MultipleImgSlider from "../components/MultipleImgSlider.vue";

export default {
  data() {
    return {
      testimonies: [],
    };
  },
  components: {
    SingleImgSlider,
    MultipleImgSlider,
  },
  mounted(){
    axios
      .get("/testimony/random")
      .then((res) => {
        let arr = res.data;
        let result = arr.map(
          item =>(
              { name: item.country, text: item.testimony, class:'show', testifier:item.firstname }
          ));
        this.testimonies = result;
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }
};
</script>

<style scoped>
.w_30,
.w_40,
.w_50,
.w_60,
.w_70 {
  width: 100% !important;
}

.section_1 > div {
  height: 250px;
}
.section_1 video {
  object-fit: cover;
  object-position: center center;
}

.section_2 h2 {
  font-size: 20px;
}

.section_3 p {
  font-size: 13px;
}
.section_3 .custom_height {
  height: 42%;
}

.section_4 a:hover {
  color: #ff3c38;
  transition: 0.7s;
}
.section_4 .img_container_1 > a,
.section_4 .img_container_2 > a {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../assets/salvation_prayer.jpg");
  background-size: cover !important;
}
.section_4 .img_container_1 > a:hover {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("../assets/salvation_prayer.jpg");
}
.section_4 .img_container_2 > a {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../assets/devotional.jpg");
}
.section_4 .img_container_2 > a:hover {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("../assets/devotional.jpg");
}

@media screen and (min-width: 768px) {
  .section_1 > div {
    height: 50vh;
  }
  .section_2 h2 {
    font-size: 28px;
  }
  .section_4 > div {
    height: 200px;
  }
}

@media screen and (min-width: 992px) {
  .w_30 {
    width: 30%;
  }
  .w_40 {
    width: 40%;
  }
  .w_50 {
    width: 50%;
  }
  .w_60 {
    width: 60%;
  }
  .w_70 {
    width: 70% !important;
  }
  .testimony {
    height: 400px;
  }
  .section_4 > div {
    height: 300px;
  }
}
</style>
