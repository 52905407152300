<template>
  <swiper
    :navigation="true"
    :modules="modules"
    :pagination="pagination"
    :autoplay="autoplay"
    :grabCursor="true"
    class="mySwiper"
  >
    <swiper-slide
      v-for="image in images"
      :key="image"
      class="primary_bg_color"
      ><img :src="image.src" alt="#" class="image" />
    </swiper-slide>
  </swiper>
</template>
  
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import { Navigation, Pagination, Autoplay } from "swiper";

export default {
  data() {
    return {
      images: [
        {
          src: require("../assets/Community_of_ministers/11_TURNING_POINT.jpg"),
          h2: "",
          p: "",
        },
        {
          src: require("../assets/Community_of_ministers/24_God's_House_of_Refuge.jpg"),
          h2: "",
          p: "",
        },
        {
          src: require("../assets/Community_of_ministers/5_Rev_Biyi_Ajala.jpg"),
          h2: "",
          p: "",
        },
        {
          src: require("../assets/Community_of_ministers/6_Destiny_Encounter.jpg"),
          h2: "",
          p: "",
        },
        {
          src: require("../assets/Community_of_ministers/4_life_oasis.jpg"),
          h2: "",
          p: "",
        },
      ],
    };
  },
  methods: {
    show(i) {
      document
        .getElementsByClassName("img-container")[i].classList.remove("d-none");
      document.getElementsByClassName("image")[i].classList.add("blur");
    },
    hide(i) {
      document
        .getElementsByClassName("img-container")[i].classList.add("d-none");
      document.getElementsByClassName("image")[i].classList.remove("blur");
    },
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Navigation, Pagination, Autoplay],
      pagination: {
        clickable: true,
      },
      autoplay: {
        delay: 7000,
        disableOnInteraction: false,
      },
    };
  },
};
</script>
    
  
<style scoped>
.swiper-pagination-bullet-active {
  background-color: #000 !important;
}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blur {
  /* Add the blur effect */
  filter: blur(4px);
  background-color: black;
}
.primary_bg_color {
  background-color: #0f0f0f !important;
}
</style>
