<template>
  <main class="secondary_bg_color">
    <div class="d-md-flex justify-content-between w_80 m-auto">
      <!--! Link to image https://static.wixstatic.com/media/d2ee5e_f632d556cddc495f91415ab1dfde6d73~mv2.jpg/v1/fill/w_980,h_446,al_c,q_90,enc_auto/d2ee5e_f632d556cddc495f91415ab1dfde6d73~mv2.jpg -->
      <div class="
          w_50
          py-4 py-md-4
          primary_text_color
          d-md-flex
          flex-column
          justify-content-center
        ">
        <h3 class="fs-2">Request Further Information</h3>
        <div class="contact-info">
          <p>South Africa Office</p>
          <p>68 Oxford Street</p>
          <p>Ferndale, Randburg</p>
          <p>South Africa. 2194</p>
          <a href="mailto:truthpartners@omegachannel.tv"
            class="call_to_action_text_color text-decoration-none">truthpartners@omegachannel.tv</a>
          <p>
            Phone: + 27 11 781 8714(Landline) 
            <a class="text-decoration-none" href="https://wa.me/+27782003226" target="blank">Text/WhatsApp: +27 78 200 3226</a>
          </p>
          <!--! Text/WhatsApp Number +27 78 200 3226 -->
          <div class="primary_bg_color p-2" style="width: fit-content">
            <SocialLinks />
          </div>
        </div>
      </div>
      <div class="w_50 pb-3 py-md-5 primary_text_color">
        <div class="w_75 m-auto bg-white shadow-lg text-center p-4 rounded">
          <div class="w-100">
            <label for="name" class="d-block">Name</label>
            <input type="text" required v-model="name" id="contact_name" class="w-100 rounded ps-2 py-1" />
          </div>
          <div class="w-100 pt-2">
            <label for="e-mail" class="d-block">Email Address</label>
            <input type="email" required v-model="email" id="contact_email" class="w-100 rounded ps-2 py-1" />
          </div>
          <div class="w-100 pt-2">
            <label for="message" class="d-block">Message</label>
            <textarea v-model="message" name="message" id="contact_msg" cols="30" rows="7"
              class="w-100 rounded ps-2 py-1" required></textarea>
          </div>
          <div id="captchat1" class="w_90 m-auto mt-2">
            <vue-recaptcha sitekey="6LfNvB8jAAAAAJ5sBkFcQCNVHfm8fXz9eeW5Xbyk"></vue-recaptcha>
          </div>
          <div class="w-100">
            <button @click="submitMessage" class="
                btn
                call_to_action_bg_color
                w-100
                mt-3
                m-auto
                secondary_text_color
              " :disabled="btnActive">
              <span>{{ btnValue }}</span>
            </button>
          </div>
          <div class="w-100">
            <label class="d-block text-danger">{{ errmsg }}</label>
            <label class="d-block text-success">{{ successMsg }}</label>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import axios from 'axios';
import SocialLinks from "../components/SocialLinks.vue";
import { VueRecaptcha } from 'vue-recaptcha';

export default {
  components: { SocialLinks, VueRecaptcha },
  data() {
    return {
      name: '',
      email: '',
      message: 'Type your message here.....',
      errmsg: '',
      successMsg: '',
      btnValue: 'Submit',
      btnActive: false
    };
  },
  methods: {
    submitMessage() {
      var baseURL = process.env.VUE_APP_ROOT_API;
      console.log(baseURL);
      this.btnValue = 'sending...';
      this.btnActive = true;
      if (this.name.length != 0 && this.email.length != 0 && this.message.length != 0) {
        this.errmsg = '';
        var formData = new FormData();
        formData.append('name', this.name);
        formData.append('email', this.email);
        formData.append('message', this.message);

        axios.post(baseURL + "/contactus", formData).then(res => {
          console.log(res);
          this.btnValue = 'Submit';
          this.btnActive = false;
          this.successMsg = 'Your message has been sent successfully';
        }).catch(err => {
          console.log(err)
          this.btnValue = 'Submit';
          this.btnActive = false;
          this.errmsg = 'Message failed to send, Please try again';
        });
      } else {
        this.errmsg = 'Please fill in   all fields';
        this.btnValue = 'Submit';
        this.btnActive = false;
      }
    }
  }
};
</script>

<style scoped>
.w_50,
.w_75 {
  width: 100%;
}

img {
  height: 400px;
}

input,
textarea {
  border: 1px solid #d8dad3 !important;
}

input:focus,
textarea:focus {
  outline: none;
  border: 1px solid #ff3c38 !important;
}

.contact-info p {
  line-height: 40px;
  font-size: 14px;
  line-height: 2;
}

@media screen and (min-width: 992px) {
  .w_50 {
    width: 50%;
  }

  .w_75 {
    width: 75%;
  }

  img {
    height: 100% !important;
  }
}
</style>