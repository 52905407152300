<template>
  <main class="secondary_bg_color">
    <div class="w_90 m-auto py-3 pb-5">
      <h2 class="fs-4 fw-bold call_to_action_text_color">On Demand</h2>
      <div
        class="container text-center text-lg-start m-auto p-0"
        style="max-width: 100% !important"
      >
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3">
          <div
            class="mb-3 rounded"
            v-for="video in youTubeVideos"
            :key="video"
            style="height: 200px"
          >
            <iframe
              :src="video.src"
              title="YouTube video player"
              class="w-100 h-100"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import axios from "../config/axios.js";
export default {
  data() {
    return {
      youTubeVideos: [
        { src: "https://www.youtube.com/embed/8f3IoSoxngM" },
        { src: "https://www.youtube.com/embed/PTNwdgVpSQg" },
        { src: "https://www.youtube.com/embed/uuzxD7rrXKQ" },
        { src: "https://www.youtube.com/embed/0ShCsU8kKuc" },
        { src: "https://www.youtube.com/embed/JqDVL_x-6CI" },
        { src: "https://www.youtube.com/embed/6BAdsuOzbL8" },
        { src: "https://www.youtube.com/embed/Rll3mxo4zvQ" },
        { src: "https://www.youtube.com/embed/mmYD_1GwFz8" },
        { src: "https://www.youtube.com/embed/2Y54rr1mMe4" },
        { src: "https://www.youtube.com/embed/MXx_fclQ8Uw" },
        { src: "https://www.youtube.com/embed/I1qR7dHXq-s" },
        { src: "https://www.youtube.com/embed/dGQAsuVxm3k" },
        { src: "https://www.youtube.com/embed/SWpAIBMvH4g" },
        { src: "https://www.youtube.com/embed/bTFHmGkgucU" },
        { src: "https://www.youtube.com/embed/ERPlgO-LIL0" },
        { src: "https://www.youtube.com/embed/I6WI28m1pc8" },
        { src: "https://www.youtube.com/embed/_mflQQ17RYM" },
        { src: "https://www.youtube.com/embed/CH6d1EsyMVc" },
        { src: "https://www.youtube.com/embed/kjyDJprI5ks" },
        { src: "https://www.youtube.com/embed/Rpb9DMXQnHU" },
        { src: "https://www.youtube.com/embed/u3XPLkLS4Lw" },
        { src: "https://www.youtube.com/embed/1Ez6_YC7KP4" },
      ],
    };
  },
  methods: {},
  mounted() {
    axios
      .get("/getvideos")
      .then((res) => {
        console.log(res.data);
        let arr = res.data;
        let result = arr.map((item) => ({
          title: item.title,
          id: item.id,
          src: item.video_link,
          minister: item.minister,
        }));
        this.videosTest = result;
        console.log(result);
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>

<style scoped>
iframe,
img {
  width: -webkit-fill-available;
}

@media screen and (min-width: 1440px) {
}
</style>