<template>
  <main class="community secondary_bg_color pt-3">
    <div class="mx-3">
      <!-- <a href="#">
        <div class="row row-cols-1 row-cols-sm-2">
          <div
            class="text-white position-relative d-flex justify-content-center mb-3"
            :class="image.class"
            v-for="(image, index) in filteredImages(0, 2)"
            :key="image"
            @mouseenter="hide(index)"
            @mouseleave="show(index)"
          >
            <img
              :src="image.src"
              :alt="image.alt"
              class="custom_border_radius w-100"
            /> -->
      <!-- <div class="text-overlay ps-3 pe-5 d_none position-absolute custom_border_radius">
              <h5 class="card-title fw-bold fs-6">
                {{ image.h5 }}
              </h5>
              <p class="card-text text-white-50 pt-1">
                {{ image.p }}
              </p>
            </div> -->
      <!-- <div class="icons d_none position-absolute">
              <a :href="image.src" class="text-white" download>
                <svg
                  class="me-2 pointer"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  width="24"
                  height="24"
                >
                  <path
                    d="M4.9937,15.9971 L4.9937,18.4971 C4.9937,18.7721 5.2177,18.9971 5.4937,18.9971 L5.4937,18.9971 L17.4937,18.9971 C17.7697,18.9971 17.9937,18.7721 17.9937,18.4971 L17.9937,18.4971 L17.9937,15.9971 L18.9937,15.9971 L18.9937,18.4971 C18.9937,19.3241 18.3207,19.9971 17.4937,19.9971 L17.4937,19.9971 L5.4937,19.9971 C4.6657,19.9971 3.9937,19.3241 3.9937,18.4971 L3.9937,18.4971 L3.9937,15.9971 L4.9937,15.9971 Z M11.9933,4 L11.9933,14.078 L15.0293,11.043 L15.7363,11.75 L11.4933,15.992 L7.2513,11.75 L7.9583,11.043 L10.9933,14.078 L10.9933,4 L11.9933,4 Z"
                    fill-rule="evenodd"
                  ></path>
                </svg>
              </a>
              Button trigger modal
              <a
                class="text-white"
                data-bs-toggle="modal"
                :data-bs-target="'#exampleModal' + index"
                v-on:click="displayModal(index)"
              >
                <svg
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  width="24"
                  height="24"
                  class="pointer"
                >
                  <path
                    d="M14.5,14 C14.628,14 14.752,14.049 14.845,14.138 C14.944,14.232 15,14.363 15,14.5 L15,17.293 L20.293,12 L15,6.707 L15,9.5 C15,9.633 14.947,9.761 14.853,9.854 C14.759,9.947 14.632,10 14.5,10 C14.494,9.998 14.491,10 14.486,10 C13.667,10 7.407,10.222 4.606,16.837 C7.276,14.751 10.496,13.795 14.188,13.989 C14.324,13.996 14.426,14.001 14.476,14.001 C14.484,14 14.492,14 14.5,14 M3.5,19 C3.414,19 3.328,18.979 3.25,18.933 C3.052,18.819 2.957,18.585 3.019,18.365 C5.304,10.189 11.981,9.145 14,9.017 L14,5.5 C14,5.298 14.122,5.115 14.309,5.038 C14.496,4.963 14.71,5.004 14.854,5.146 L21.354,11.646 C21.549,11.842 21.549,12.158 21.354,12.354 L14.854,18.854 C14.71,18.997 14.495,19.038 14.309,18.962 C14.122,18.885 14,18.702 14,18.5 L14,14.981 C9.957,14.791 6.545,16.102 3.857,18.85 C3.761,18.948 3.631,19 3.5,19"
                    fill-rule="evenodd"
                  ></path>
                </svg>
              </a>
              Modal
              <div
                class="modal fade"
                :id="'exampleModal' + index"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content rounded-0">
                    <div class="modal-header border-0">
                      <button
                        type="button"
                        class="btn-close pe-5"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div class="modal-body">
                      <div class="w-50 m-auto d-flex justify-content-around">
                        <a href="#" class="text-black opacity-100"
                          ><svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            class="social-share-icon"
                          >
                            <path
                              d="M24 12c0-6.627-5.373-12-12-12S0 5.373 0 12c0 5.99 4.388 10.954 10.125 11.854V15.47H7.078V12h3.047V9.356c0-3.007 1.792-4.668 4.533-4.668 1.312 0 2.686.234 2.686.234v2.953H15.83c-1.491 0-1.956.925-1.956 1.874V12h3.328l-.532 3.469h-2.796v8.385C19.612 22.954 24 17.99 24 12"
                              transform="translate(-517 -407) translate(394 248) translate(123 159)"
                            ></path>
                          </svg>
                        </a>
                        <a href="#" class="text-black opacity-100"
                          ><svg
                            viewBox="0 0 16 13"
                            fill="currentColor"
                            width="24"
                            height="24"
                            class="social-share-icon"
                          >
                            <path
                              d="M 15.99 1.6C 15.91 2.19 15.04 2.79 14.51 3.23 15.03 10.77 6.33 15.48 0.01 11.66 1.78 11.67 3.78 11.19 4.83 10.21 3.3 9.96 2.21 9.28 1.79 7.94 2.24 7.9 2.87 8.04 3.17 7.85 1.77 7.33 0.66 6.52 0.59 4.68 1.09 4.73 1.35 5.02 1.97 4.95 1.06 4.38 0.03 2.21 0.96 0.61 2.61 2.4 4.66 3.79 7.87 4.05 7.08 0.72 11.57-1.34 13.59 1.06 14.38 0.89 15.04 0.61 15.71 0.33 15.44 1.09 14.88 1.57 14.33 2.06 14.92 1.95 15.55 1.87 15.99 1.6Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </a>
                        <a href="#" class="text-black opacity-100"
                          ><svg
                            viewBox="0 0 15 15"
                            fill="currentColor"
                            width="24"
                            height="24"
                            class="social-share-icon"
                          >
                            <path
                              d="M 7.5 0.01C 3.36 0.01 0 3.37 0 7.5 0 10.69 1.99 13.4 4.79 14.49 4.74 14.01 4.69 13.04 4.83 12.44 4.97 11.85 5.73 8.65 5.73 8.65 5.73 8.65 5.5 8.19 5.5 7.52 5.5 6.46 6.11 5.66 6.88 5.66 7.53 5.66 7.85 6.15 7.85 6.74 7.85 7.39 7.43 8.37 7.21 9.28 7.03 10.04 7.6 10.66 8.34 10.66 9.7 10.66 10.74 9.23 10.74 7.16 10.74 5.34 9.43 4.06 7.56 4.06 5.38 4.06 4.11 5.69 4.11 7.37 4.11 8.03 4.36 8.73 4.68 9.11 4.74 9.19 4.75 9.25 4.73 9.33 4.67 9.57 4.54 10.09 4.52 10.2 4.48 10.34 4.41 10.37 4.26 10.3 3.31 9.86 2.71 8.46 2.71 7.34 2.71 4.93 4.46 2.72 7.76 2.72 10.4 2.72 12.46 4.61 12.46 7.13 12.46 9.76 10.8 11.88 8.5 11.88 7.73 11.88 7 11.48 6.75 11 6.75 11 6.37 12.46 6.28 12.82 6.12 13.43 5.71 14.19 5.4 14.7 6.07 14.89 6.77 15 7.5 15 11.64 15 14.99 11.64 14.99 7.5 14.99 3.37 11.64 0.01 7.5 0.01Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </a>
                        <a href="#" class="text-black opacity-100"
                          ><svg
                            viewBox="0 0 8 15"
                            fill="currentColor"
                            width="24"
                            height="24"
                            class="social-share-icon"
                          >
                            <path
                              d="M 7.99 6.38C 7.99 6.38 5 6.38 5 6.38 5 6.38 5 10.53 5 10.53 5 11.28 5.09 11.7 6.09 11.7 6.09 11.7 7.99 11.7 7.99 11.7 7.99 11.7 7.99 14.89 7.99 14.89 7.99 14.89 6.99 15 5.89 15 3.3 15 2 13.29 2 11.38 2 11.38 2 6.38 2 6.38 2 6.38 0.01 6.38 0.01 6.38 0.01 6.38 0.01 3.41 0.01 3.41 2.4 3.2 2.6 1.28 2.8 0 2.8 0 5 0 5 0 5 0 5 3.2 5 3.2 5 3.2 7.99 3.2 7.99 3.2 7.99 3.2 7.99 6.38 7.99 6.38Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </a>
                        <a href="#" class="text-black opacity-100"
                          ><svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            width="24"
                            height="24"
                            viewBox="0 0 24 18"
                            class="social-share-icon"
                          >
                            <path
                              d="M246.009 7l10.536 6.867c1.155.86 2.728.852 3.873-.021L270 7.464v10.829c0 1.465-1.223 2.707-2.667 2.707h-18.666c-1.444-.001-2.668-.971-2.665-2.436L246.009 7zm21.319-4c1.249 0 2.41 1.5 2.672 2.669l-10.976 7.14c-.343.254-.81.254-1.153 0L246 5.099C246.24 3.9 247.299 3 248.568 3h18.76z"
                              transform="translate(-763 -410) translate(394 248) translate(123 159)"
                            ></path>
                          </svg>
                        </a>
                      </div>
                      <div class="w-50 m-auto d-flex pt-4 btn-holder">
                        <input
                          type="text"
                          ref="myinput"
                          readonly
                          :value="image.link"
                          class="w-100 p-2 copyText"
                        />
                        <button
                          class="bg-black"
                          title="Copy Link"
                          @click="copyURL(image.link, index)"
                        >
                          <img
                            class="copybtn"
                            src="../assets/copy.png"
                            alt=""
                            width="25"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
      <!-- </div>
        </div>
      </a> -->
      <a href="#">
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3">
          <div class="text-white position-relative d-flex justify-content-center mb-3" :class="image.class"
            v-for="(image, index) in images" :key="image" @mouseenter="hide(index + 2)" @mouseleave="show(index + 2)">
            <div class="me-1 border-0">
              <img :src="image.src" :alt="image.alt" class="w-100 custom_border_radius" />
            </div>

            <!-- <div class="text-overlay ps-3 pe-5 d_none position-absolute sec-2 custom_border_radius">
            <h5 class="card-title fs-6 fw-bold">
              {{ image.h5 }}
            </h5>
            <p class="card-text text-white-50 pt-1">
              {{ image.p }}
            </p>
          </div> -->
            <!-- <div class="icons d_none position-absolute">
            Button trigger modal
            <a :href="image.src" class="text-white" download>
              <svg
                class="me-2 pointer"
                viewBox="0 0 24 24"
                fill="currentColor"
                width="24"
                height="24"
              >
                <path
                  d="M4.9937,15.9971 L4.9937,18.4971 C4.9937,18.7721 5.2177,18.9971 5.4937,18.9971 L5.4937,18.9971 L17.4937,18.9971 C17.7697,18.9971 17.9937,18.7721 17.9937,18.4971 L17.9937,18.4971 L17.9937,15.9971 L18.9937,15.9971 L18.9937,18.4971 C18.9937,19.3241 18.3207,19.9971 17.4937,19.9971 L17.4937,19.9971 L5.4937,19.9971 C4.6657,19.9971 3.9937,19.3241 3.9937,18.4971 L3.9937,18.4971 L3.9937,15.9971 L4.9937,15.9971 Z M11.9933,4 L11.9933,14.078 L15.0293,11.043 L15.7363,11.75 L11.4933,15.992 L7.2513,11.75 L7.9583,11.043 L10.9933,14.078 L10.9933,4 L11.9933,4 Z"
                  fill-rule="evenodd"
                ></path>
              </svg>
            </a>
            <a
              class="text-white"
              data-bs-toggle="modal"
              :data-bs-target="'#exampleModal' + index + 2"
              v-on:click="displayModal(index + 2)"
            >
              <svg
                viewBox="0 0 24 24"
                fill="currentColor"
                width="24"
                height="24"
                class="pointer"
              >
                <path
                  d="M14.5,14 C14.628,14 14.752,14.049 14.845,14.138 C14.944,14.232 15,14.363 15,14.5 L15,17.293 L20.293,12 L15,6.707 L15,9.5 C15,9.633 14.947,9.761 14.853,9.854 C14.759,9.947 14.632,10 14.5,10 C14.494,9.998 14.491,10 14.486,10 C13.667,10 7.407,10.222 4.606,16.837 C7.276,14.751 10.496,13.795 14.188,13.989 C14.324,13.996 14.426,14.001 14.476,14.001 C14.484,14 14.492,14 14.5,14 M3.5,19 C3.414,19 3.328,18.979 3.25,18.933 C3.052,18.819 2.957,18.585 3.019,18.365 C5.304,10.189 11.981,9.145 14,9.017 L14,5.5 C14,5.298 14.122,5.115 14.309,5.038 C14.496,4.963 14.71,5.004 14.854,5.146 L21.354,11.646 C21.549,11.842 21.549,12.158 21.354,12.354 L14.854,18.854 C14.71,18.997 14.495,19.038 14.309,18.962 C14.122,18.885 14,18.702 14,18.5 L14,14.981 C9.957,14.791 6.545,16.102 3.857,18.85 C3.761,18.948 3.631,19 3.5,19"
                  fill-rule="evenodd"
                ></path>
              </svg>
            </a>
            Modal
            <div
              class="modal fade"
              :id="'exampleModal' + index + 2"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content rounded-0">
                  <div class="modal-body">
                    <div class="w-50 m-auto d-flex justify-content-around">
                      <a href="#" class="text-black opacity-100"
                        ><svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          class="social-share-icon"
                        >
                          <path
                            d="M24 12c0-6.627-5.373-12-12-12S0 5.373 0 12c0 5.99 4.388 10.954 10.125 11.854V15.47H7.078V12h3.047V9.356c0-3.007 1.792-4.668 4.533-4.668 1.312 0 2.686.234 2.686.234v2.953H15.83c-1.491 0-1.956.925-1.956 1.874V12h3.328l-.532 3.469h-2.796v8.385C19.612 22.954 24 17.99 24 12"
                            transform="translate(-517 -407) translate(394 248) translate(123 159)"
                          ></path>
                        </svg>
                      </a>
                      <a href="#" class="text-black opacity-100"
                        ><svg
                          viewBox="0 0 16 13"
                          fill="currentColor"
                          width="24"
                          height="24"
                          class="social-share-icon"
                        >
                          <path
                            d="M 15.99 1.6C 15.91 2.19 15.04 2.79 14.51 3.23 15.03 10.77 6.33 15.48 0.01 11.66 1.78 11.67 3.78 11.19 4.83 10.21 3.3 9.96 2.21 9.28 1.79 7.94 2.24 7.9 2.87 8.04 3.17 7.85 1.77 7.33 0.66 6.52 0.59 4.68 1.09 4.73 1.35 5.02 1.97 4.95 1.06 4.38 0.03 2.21 0.96 0.61 2.61 2.4 4.66 3.79 7.87 4.05 7.08 0.72 11.57-1.34 13.59 1.06 14.38 0.89 15.04 0.61 15.71 0.33 15.44 1.09 14.88 1.57 14.33 2.06 14.92 1.95 15.55 1.87 15.99 1.6Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </a>
                      <a href="#" class="text-black opacity-100"
                        ><svg
                          viewBox="0 0 15 15"
                          fill="currentColor"
                          width="24"
                          height="24"
                          class="social-share-icon"
                        >
                          <path
                            d="M 7.5 0.01C 3.36 0.01 0 3.37 0 7.5 0 10.69 1.99 13.4 4.79 14.49 4.74 14.01 4.69 13.04 4.83 12.44 4.97 11.85 5.73 8.65 5.73 8.65 5.73 8.65 5.5 8.19 5.5 7.52 5.5 6.46 6.11 5.66 6.88 5.66 7.53 5.66 7.85 6.15 7.85 6.74 7.85 7.39 7.43 8.37 7.21 9.28 7.03 10.04 7.6 10.66 8.34 10.66 9.7 10.66 10.74 9.23 10.74 7.16 10.74 5.34 9.43 4.06 7.56 4.06 5.38 4.06 4.11 5.69 4.11 7.37 4.11 8.03 4.36 8.73 4.68 9.11 4.74 9.19 4.75 9.25 4.73 9.33 4.67 9.57 4.54 10.09 4.52 10.2 4.48 10.34 4.41 10.37 4.26 10.3 3.31 9.86 2.71 8.46 2.71 7.34 2.71 4.93 4.46 2.72 7.76 2.72 10.4 2.72 12.46 4.61 12.46 7.13 12.46 9.76 10.8 11.88 8.5 11.88 7.73 11.88 7 11.48 6.75 11 6.75 11 6.37 12.46 6.28 12.82 6.12 13.43 5.71 14.19 5.4 14.7 6.07 14.89 6.77 15 7.5 15 11.64 15 14.99 11.64 14.99 7.5 14.99 3.37 11.64 0.01 7.5 0.01Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </a>
                      <a href="#" class="text-black opacity-100"
                        ><svg
                          viewBox="0 0 8 15"
                          fill="currentColor"
                          width="24"
                          height="24"
                          class="social-share-icon"
                        >
                          <path
                            d="M 7.99 6.38C 7.99 6.38 5 6.38 5 6.38 5 6.38 5 10.53 5 10.53 5 11.28 5.09 11.7 6.09 11.7 6.09 11.7 7.99 11.7 7.99 11.7 7.99 11.7 7.99 14.89 7.99 14.89 7.99 14.89 6.99 15 5.89 15 3.3 15 2 13.29 2 11.38 2 11.38 2 6.38 2 6.38 2 6.38 0.01 6.38 0.01 6.38 0.01 6.38 0.01 3.41 0.01 3.41 2.4 3.2 2.6 1.28 2.8 0 2.8 0 5 0 5 0 5 0 5 3.2 5 3.2 5 3.2 7.99 3.2 7.99 3.2 7.99 3.2 7.99 6.38 7.99 6.38Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </a>
                      <a href="#" class="text-black opacity-100"
                        ><svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          width="24"
                          height="24"
                          viewBox="0 0 24 18"
                          class="social-share-icon"
                        >
                          <path
                            d="M246.009 7l10.536 6.867c1.155.86 2.728.852 3.873-.021L270 7.464v10.829c0 1.465-1.223 2.707-2.667 2.707h-18.666c-1.444-.001-2.668-.971-2.665-2.436L246.009 7zm21.319-4c1.249 0 2.41 1.5 2.672 2.669l-10.976 7.14c-.343.254-.81.254-1.153 0L246 5.099C246.24 3.9 247.299 3 248.568 3h18.76z"
                            transform="translate(-763 -410) translate(394 248) translate(123 159)"
                          ></path>
                        </svg>
                      </a>
                    </div>
                    <div class="w-50 m-auto d-flex pt-4 btn-holder">
                      <input
                        type="text"
                        class="w-100 p-2"
                        value="https://omegagospeltvnetwork.wixsite.com/omegagospeltv/shows?pgid=jdu5i6pi-0d58172d-0034-4729-9ac3-4655f36e58f0"
                      />
                      <button
                        class="bg-black"
                        title="Copy Link"
                        @click="copyURL(image.link, index + 2)"
                      >
                        <img
                          class="copybtn"
                          src="../assets/copy.png"
                          alt=""
                          width="25"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          </div>
        </div>
      </a>
      <!-- <a href="#">
        <div class="row row-cols-1 row-cols-sm-2">
          <div
            class="text-white position-relative d-flex justify-content-center mb-3"
            :class="image.class"
            v-for="(image, index) in filteredImages(
              images.length - 2,
              images.length
            )"
            :key="image"
            @mouseenter="hide(index + images.length - 2)"
            @mouseleave="show(index + images.length - 2)"
          >
            <img
              :src="image.src"
              :alt="image.alt"
              class="w-100 custom_border_radius"
            /> -->
      <!-- <div class="text-overlay ps-3 pe-5 d_none position-absolute sec-3 custom_border_radius">
            <h5 class="card-title fs-6 fw-bold">
              {{ image.h5 }}
            </h5>
            <p class="card-text text-white-50 pt-1">
              {{ image.p }}
            </p>
          </div> -->
      <!-- <div id="btn" class="icons d_none position-absolute">
            <a :href="image.src" class="text-white" download>
              <svg
                class="me-2 pointer"
                viewBox="0 0 24 24"
                fill="currentColor"
                width="24"
                height="24"
              >
                <path
                  d="M4.9937,15.9971 L4.9937,18.4971 C4.9937,18.7721 5.2177,18.9971 5.4937,18.9971 L5.4937,18.9971 L17.4937,18.9971 C17.7697,18.9971 17.9937,18.7721 17.9937,18.4971 L17.9937,18.4971 L17.9937,15.9971 L18.9937,15.9971 L18.9937,18.4971 C18.9937,19.3241 18.3207,19.9971 17.4937,19.9971 L17.4937,19.9971 L5.4937,19.9971 C4.6657,19.9971 3.9937,19.3241 3.9937,18.4971 L3.9937,18.4971 L3.9937,15.9971 L4.9937,15.9971 Z M11.9933,4 L11.9933,14.078 L15.0293,11.043 L15.7363,11.75 L11.4933,15.992 L7.2513,11.75 L7.9583,11.043 L10.9933,14.078 L10.9933,4 L11.9933,4 Z"
                  fill-rule="evenodd"
                ></path>
              </svg>
            </a>
            Button trigger modal
            <a
              class="text-white"
              data-bs-toggle="modal"
              :data-bs-target="'#exampleModal' + index + 3"
              v-on:click="displayModal(index + images.length - 2)"
            >
              <svg
                viewBox="0 0 24 24"
                fill="currentColor"
                width="24"
                height="24"
                class="pointer"
              >
                <path
                  d="M14.5,14 C14.628,14 14.752,14.049 14.845,14.138 C14.944,14.232 15,14.363 15,14.5 L15,17.293 L20.293,12 L15,6.707 L15,9.5 C15,9.633 14.947,9.761 14.853,9.854 C14.759,9.947 14.632,10 14.5,10 C14.494,9.998 14.491,10 14.486,10 C13.667,10 7.407,10.222 4.606,16.837 C7.276,14.751 10.496,13.795 14.188,13.989 C14.324,13.996 14.426,14.001 14.476,14.001 C14.484,14 14.492,14 14.5,14 M3.5,19 C3.414,19 3.328,18.979 3.25,18.933 C3.052,18.819 2.957,18.585 3.019,18.365 C5.304,10.189 11.981,9.145 14,9.017 L14,5.5 C14,5.298 14.122,5.115 14.309,5.038 C14.496,4.963 14.71,5.004 14.854,5.146 L21.354,11.646 C21.549,11.842 21.549,12.158 21.354,12.354 L14.854,18.854 C14.71,18.997 14.495,19.038 14.309,18.962 C14.122,18.885 14,18.702 14,18.5 L14,14.981 C9.957,14.791 6.545,16.102 3.857,18.85 C3.761,18.948 3.631,19 3.5,19"
                  fill-rule="evenodd"
                ></path>
              </svg>
            </a>
            Modal
            <div
              class="modal fade"
              :id="'exampleModal' + index + 3"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content rounded-0">
                  <div class="modal-body">
                    <div class="w-50 m-auto d-flex justify-content-around">
                      <a href="#" class="text-black opacity-100"
                        ><svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          class="social-share-icon"
                        >
                          <path
                            d="M24 12c0-6.627-5.373-12-12-12S0 5.373 0 12c0 5.99 4.388 10.954 10.125 11.854V15.47H7.078V12h3.047V9.356c0-3.007 1.792-4.668 4.533-4.668 1.312 0 2.686.234 2.686.234v2.953H15.83c-1.491 0-1.956.925-1.956 1.874V12h3.328l-.532 3.469h-2.796v8.385C19.612 22.954 24 17.99 24 12"
                            transform="translate(-517 -407) translate(394 248) translate(123 159)"
                          ></path>
                        </svg>
                      </a>
                      <a href="#" class="text-black opacity-100"
                        ><svg
                          viewBox="0 0 16 13"
                          fill="currentColor"
                          width="24"
                          height="24"
                          class="social-share-icon"
                        >
                          <path
                            d="M 15.99 1.6C 15.91 2.19 15.04 2.79 14.51 3.23 15.03 10.77 6.33 15.48 0.01 11.66 1.78 11.67 3.78 11.19 4.83 10.21 3.3 9.96 2.21 9.28 1.79 7.94 2.24 7.9 2.87 8.04 3.17 7.85 1.77 7.33 0.66 6.52 0.59 4.68 1.09 4.73 1.35 5.02 1.97 4.95 1.06 4.38 0.03 2.21 0.96 0.61 2.61 2.4 4.66 3.79 7.87 4.05 7.08 0.72 11.57-1.34 13.59 1.06 14.38 0.89 15.04 0.61 15.71 0.33 15.44 1.09 14.88 1.57 14.33 2.06 14.92 1.95 15.55 1.87 15.99 1.6Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </a>
                      <a href="#" class="text-black opacity-100"
                        ><svg
                          viewBox="0 0 15 15"
                          fill="currentColor"
                          width="24"
                          height="24"
                          class="social-share-icon"
                        >
                          <path
                            d="M 7.5 0.01C 3.36 0.01 0 3.37 0 7.5 0 10.69 1.99 13.4 4.79 14.49 4.74 14.01 4.69 13.04 4.83 12.44 4.97 11.85 5.73 8.65 5.73 8.65 5.73 8.65 5.5 8.19 5.5 7.52 5.5 6.46 6.11 5.66 6.88 5.66 7.53 5.66 7.85 6.15 7.85 6.74 7.85 7.39 7.43 8.37 7.21 9.28 7.03 10.04 7.6 10.66 8.34 10.66 9.7 10.66 10.74 9.23 10.74 7.16 10.74 5.34 9.43 4.06 7.56 4.06 5.38 4.06 4.11 5.69 4.11 7.37 4.11 8.03 4.36 8.73 4.68 9.11 4.74 9.19 4.75 9.25 4.73 9.33 4.67 9.57 4.54 10.09 4.52 10.2 4.48 10.34 4.41 10.37 4.26 10.3 3.31 9.86 2.71 8.46 2.71 7.34 2.71 4.93 4.46 2.72 7.76 2.72 10.4 2.72 12.46 4.61 12.46 7.13 12.46 9.76 10.8 11.88 8.5 11.88 7.73 11.88 7 11.48 6.75 11 6.75 11 6.37 12.46 6.28 12.82 6.12 13.43 5.71 14.19 5.4 14.7 6.07 14.89 6.77 15 7.5 15 11.64 15 14.99 11.64 14.99 7.5 14.99 3.37 11.64 0.01 7.5 0.01Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </a>
                      <a href="#" class="text-black opacity-100"
                        ><svg
                          viewBox="0 0 8 15"
                          fill="currentColor"
                          width="24"
                          height="24"
                          class="social-share-icon"
                        >
                          <path
                            d="M 7.99 6.38C 7.99 6.38 5 6.38 5 6.38 5 6.38 5 10.53 5 10.53 5 11.28 5.09 11.7 6.09 11.7 6.09 11.7 7.99 11.7 7.99 11.7 7.99 11.7 7.99 14.89 7.99 14.89 7.99 14.89 6.99 15 5.89 15 3.3 15 2 13.29 2 11.38 2 11.38 2 6.38 2 6.38 2 6.38 0.01 6.38 0.01 6.38 0.01 6.38 0.01 3.41 0.01 3.41 2.4 3.2 2.6 1.28 2.8 0 2.8 0 5 0 5 0 5 0 5 3.2 5 3.2 5 3.2 7.99 3.2 7.99 3.2 7.99 3.2 7.99 6.38 7.99 6.38Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </a>
                      <a href="#" class="text-black opacity-100"
                        ><svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          width="24"
                          height="24"
                          viewBox="0 0 24 18"
                          class="social-share-icon"
                        >
                          <path
                            d="M246.009 7l10.536 6.867c1.155.86 2.728.852 3.873-.021L270 7.464v10.829c0 1.465-1.223 2.707-2.667 2.707h-18.666c-1.444-.001-2.668-.971-2.665-2.436L246.009 7zm21.319-4c1.249 0 2.41 1.5 2.672 2.669l-10.976 7.14c-.343.254-.81.254-1.153 0L246 5.099C246.24 3.9 247.299 3 248.568 3h18.76z"
                            transform="translate(-763 -410) translate(394 248) translate(123 159)"
                          ></path>
                        </svg>
                      </a>
                    </div>
                    <div class="w-50 m-auto d-flex pt-4 btn-holder">
                      <input
                        type="text"
                        class="w-100 p-2"
                        value="https://omegagospeltvnetwork.wixsite.com/omegagospeltv/shows?pgid=jdu5i6pi-0d58172d-0034-4729-9ac3-4655f36e58f0"
                      />
                      <button
                        class="bg-black"
                        title="Copy Link"
                        @click="copyURL(image.link, index + images.length - 2)"
                      >
                        <img
                          class="copybtn"
                          src="../assets/copy.png"
                          alt=""
                          width="25"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
      <!-- </div>
        </div>
      </a> -->
    </div>
  </main>
</template>

<script>
export default {
  data() {
    return {
      images: [
        // {
        //   src: require("../assets/Community_of_ministers/1_PRESENT_TRUTH.jpg"),
        //   alt: "TURNING POINT.",
        //   class: "col-sm-5",
        //   h5: "TURNING POINT INTERNATIONAL With Muyiwa Olarewaju",
        //   p: "A dynamic and lively magazine program. Inspiring and informative. This family program focuses on critical global social issues and powerful testimonies",
        //   link: "https://omegagospeltvnetwork.wixsite.com/omegagospeltv/shows?pgid=jdu5i6pi-0d58172d-0034-4729-9ac3-4655f36e58f0",
        // },
        {
          src: require("../assets/Community_of_ministers/3_Ps_Palm_banner.jpg"),
          alt: "God House of Refuge",
          class: "col mb-3",
          h5: "God's House of Refuge Ministry. With Pst. Ezekiel Atang",
          p: "If you don't have the discerning of the spirit, at least have sense to observe faulty foundation. Make a decision today for a better marriage tomorrow. powerful teachings, interpreting the message of abuse in marriage",
          link: "https://omegagospeltvnetwork.wixsite.com/omegagospeltv/shows?pgid=jdu5i6pi-7b4448db-393e-406c-bf6f-af55d516f20b",
        },
        {
          src: require("../assets/Community_of_ministers/2_The_700_club.jpg"),
          alt: "PRESENT TRUTH POSTER",
          class: "col-sm-7",
          h5: "PRESENT TRUTH with Apostle Ladi Lloyd Kuyinu",
          p: "God changes not. Neither does his word. Malachi 3:6",
          link: "https://omegagospeltvnetwork.wixsite.com/omegagospeltv/shows?pgid=jdu5i6pi-0c2c7f22-d267-4da8-ae56-cbc7ca019a50",
        },
        {
          src: require("../assets/Community_of_ministers/4_life_oasis.jpg"),
          alt: "Holding Forth the Word Rev Biyi",
          class: "col mb-3",
          h5: "Holding Forth the Word. With Rev Biyi Ajala.",
          p: "Philippians 2:16. Holding forth the word of life that I may rejoice in the day of Christ, that I have not run in vain, neither laboured in vain.",
          link: "https://omegagospeltvnetwork.wixsite.com/omegagospeltv/shows?pgid=jdu5i6pi-20362767-0289-498d-b5ba-4480f26cb4ae",
        },
        {
          src: require("../assets/Community_of_ministers/5_Rev_Biyi_Ajala.jpg"),
          alt: "Destiny Encounter",
          class: "col mb-3",
          h5: "Destiny Encounter With Pastor Peter Balogun",
          p: "For the word of God is alive and powerful. It is sharper than the sharpest two-edged sword, cutting between soul and spirit, between joint and marrow. it exposes our innermost thoughts and desires.",
          link: "https://omegagospeltvnetwork.wixsite.com/omegagospeltv/shows?pgid=jdu5i6pi-b974ce21-9842-4d45-97b6-00fc1ce85271",
        },
        {
          src: require("../assets/Community_of_ministers/6_Destiny_Encounter.jpg"),
          alt: "life oasis poster",
          class: "col mb-3",
          h5: "Living by the answer. With Rev. Olusola Areogun",
          p: "Take hold of God's provision for your life as you listen to His word of God. Get ready as Rev. Olusola Areogun brings the undiluted word of God.",
          link: "https://omegagospeltvnetwork.wixsite.com/omegagospeltv/shows?pgid=jdu5i6pi-f24a1350-0b4a-494a-9474-68ec368d4618",
        },
        {
          src: require("../assets/Community_of_ministers/7_CTC.jpg"),
          alt: "700 club",
          class: "col mb-3",
          h5: "The 700 Club",
          p: "",
          link: "https://omegagospeltvnetwork.wixsite.com/omegagospeltv/shows?pgid=jdu5i6pi-e548fb30-2c63-442c-8a31-813addf1071a",
        },
        {
          src: require("../assets/Community_of_ministers/8_Ps._Oyor.jpg"),
          alt: "poster caption",
          class: "col mb-3",
          h5: "Wisdom Connect. With Ps. Sunday Ogidigbo.",
          p: "Let your door stand open to receive Him, unlock your soul to Him, offer him a welcome in your mind, and then you will see the riches of simplicity, the treasures of peace, the joy of grace",
          link: "https://omegagospeltvnetwork.wixsite.com/omegagospeltv/shows?pgid=jdu5i6pi-f4000cf9-ced8-484b-a62b-ae00a23f5204",
        },
        {
          src: require("../assets/Community_of_ministers/9_Kings_Outreach_ministries.jpg"),
          alt: "THE HIDDEN MANNA",
          class: "col mb-3",
          h5: "THE HIDDEN MANNER.jpg",
          p: "",
          link: "https://omegagospeltvnetwork.wixsite.com/omegagospeltv/shows?pgid=jdu5i6pi-123f02c1-9cbc-4cb1-b120-6cac85b2acc7",
        },
        {
          src: require("../assets/Community_of_ministers/10_The_victory_community_baptist.jpg"),
          alt: "New Wine Apostolic Ministry",
          class: "col mb-3",
          h5: "New Wine Apostolic Ministry,jpg",
          p: "",
          link: "https://omegagospeltvnetwork.wixsite.com/omegagospeltv/shows?pgid=jdu5i6pi-f6e07ac5-0c13-4fd1-baf1-181644c04cca",
        },
        {
          src: require("../assets/Community_of_ministers/11_TURNING_POINT.jpg"),
          alt: "Kings Outreach ministries",
          class: "col mb-3",
          h5: "Kings Outreach ministries.jpg",
          p: "",
          link: "https://omegagospeltvnetwork.wixsite.com/omegagospeltv/shows?pgid=jdu5i6pi-6f633f02-8fe1-42cc-a63c-5f63beef5b9c",
        },
        {
          src: require("../assets/Community_of_ministers/12_Ps._Sunday.jpg"),
          alt: "JESUS IS LORD FOUNDATION",
          class: "col mb-3",
          h5: "JESUS IS LORD FOUNDATION.jpg",
          p: "",
          link: "https://omegagospeltvnetwork.wixsite.com/omegagospeltv/shows?pgid=jdu5i6pi-04ff7d04-9f27-4c15-bcb3-269e59a5b8ed",
        },
        {
          src: require("../assets/Community_of_ministers/13_Pastor_Charles.jpg"),
          alt: "IGEM",
          class: "col mb-3",
          h5: "IGEM.jpg",
          p: "",
          link: "https://omegagospeltvnetwork.wixsite.com/omegagospeltv/shows?pgid=jdu5i6pi-b1c097f7-a8ed-48e9-80e9-7602e30bb8ab",
        },
        {
          src: require("../assets/Community_of_ministers/14_Rev_Sade_Fasedemi.jpg"),
          alt: "The victory community baptist",
          class: "col mb-3",
          h5: "The victory community baptist.jpg",
          p: "",
          link: "https://omegagospeltvnetwork.wixsite.com/omegagospeltv/shows?pgid=jdu5i6pi-e0cdb683-efc7-415c-a6aa-7d7c0658bbc1",
        },
        {
          src: require("../assets/Community_of_ministers/15_Deven_Chetty.jpg"),
          alt: "The name of Jesus ministries",
          class: "col",
          h5: "The name of Jesus ministries.jpg",
          p: "",
          link: "https://omegagospeltvnetwork.wixsite.com/omegagospeltv/shows?pgid=jdu5i6pi-5cc64a50-14ad-405b-afeb-0baa205408ba",
        },
        {
          src: require("../assets/Community_of_ministers/16_Apostle_M Ranoto.jpg"),
          alt: "The Revealed Word",
          class: "col",
          h5: "The Revealed Word.jpg",
          p: "",
          link: "https://omegagospeltvnetwork.wixsite.com/omegagospeltv/shows?pgid=jdu5i6pi-e94595f7-7531-41bf-9fd0-7ae7beb616a3",
        },
        {
          src: require("../assets/Community_of_ministers/17_New_Wine_Apostolic_Ministry.jpg"),
          alt: "CALVARY BREAD OF LIFE",
          class: "col",
          h5: "CALVARY BREAD OF LIFE.jpg",
          p: "",
          link: "https://omegagospeltvnetwork.wixsite.com/omegagospeltv/shows?pgid=jdu5i6pi-978f8cea-fe72-451e-98db-e1e0d7459df7",
        },
        {
          src: require("../assets/Community_of_ministers/18_JESUS_IS_LORD_FOUNDATION.jpg"),
          alt: "CTC",
          class: "col",
          h5: "CTC.jpg",
          p: "",
          link: "https://omegagospeltvnetwork.wixsite.com/omegagospeltv/shows?pgid=jdu5i6pi-91663706-e77f-456d-9a13-cf5161463c75",
        },
        {
          src: require("../assets/Community_of_ministers/19_The_name_of_Jesus_ministries.jpg"),
          alt: "GOD WILL DO IT MIN",
          class: "col",
          h5: "GOD WILL DO IT MIN.jpg",
          p: "",
          link: "https://omegagospeltvnetwork.wixsite.com/omegagospeltv/shows?pgid=jdu5i6pi-6b95ee3e-9d0c-4f7d-8e73-14671eb7875b",
        },
        {
          src: require("../assets/Community_of_ministers/20_IGEM.jpg"),
          alt: "",
          class: "col",
          h5: "",
          p: "",
          link: "",
        },
        {
          src: require("../assets/Community_of_ministers/21_CALVARY_BREAD_OF_LIFE.jpg"),
          alt: "",
          class: "col",
          h5: "",
          p: "",
          link: "",
        },
        {
          src: require("../assets/Community_of_ministers/22_The_Revealed_Word.jpg"),
          alt: "",
          class: "col",
          h5: "",
          p: "",
          link: "",
        },
        {
          src: require("../assets/Community_of_ministers/23_THE_HIDDEN_MANNA.jpg"),
          alt: "",
          class: "col",
          h5: "",
          p: "",
          link: "",
        },
        {
          src: require("../assets/Community_of_ministers/24_God's_House_of_Refuge.jpg"),
          alt: "",
          class: "col",
          h5: "",
          p: "",
          link: "",
        },
        {
          src: require("../assets/Community_of_ministers/25_.jpg"),
          alt: "",
          class: "col",
          h5: "",
          p: "",
          link: "",
        },
        {
          src: require("../assets/Community_of_ministers/26_.jpg"),
          alt: "",
          class: "col",
          h5: "",
          p: "",
          link: "",
        },
        {
          src: require("../assets/Community_of_ministers/27_.jpg"),
          alt: "",
          class: "col",
          h5: "",
          p: "",
          link: "",
        },
        {
          src: require("../assets/Community_of_ministers/28_.jpg"),
          alt: "",
          class: "col",
          h5: "",
          p: "",
          link: "",
        },
        {
          src: require("../assets/Community_of_ministers/29_.jpg"),
          alt: "",
          class: "col",
          h5: "",
          p: "",
          link: "",
        },
        {
          src: require("../assets/Community_of_ministers/30_.jpg"),
          alt: "",
          class: "col",
          h5: "",
          p: "",
          link: "",
        },
        {
          src: require("../assets/Community_of_ministers/31_.jpg"),
          alt: "",
          class: "col",
          h5: "",
          p: "",
          link: "",
        },
      ],
    };
  },
  methods: {
    filteredImages: function (x, y) {
      return this.images.slice(x, y);
    },
    displayModal(i) {
      document.getElementsByClassName("modal")[i].style.display = "block";
      document.getElementsByClassName("modal")[i].classList.add("show");
    },
    async copyURL(mytext, img) {
      try {
        await navigator.clipboard.writeText(mytext);
        document.getElementsByClassName("copybtn")[
          img
        ].src = require("../assets/check.png");
        setTimeout(() => {
          document.getElementsByClassName("copybtn")[
            img
          ].src = require("../assets/copy.png");
        }, 2000);
      } catch ($e) {
        console.log("Cannot copy");
      }
    },
    hide(i) {
      document
        .getElementsByClassName("text-overlay")[i].classList.remove("d_none");
      document.getElementsByClassName("icons")[i].classList.remove("d_none");
      document;
    },
    show(i) {
      document
        .getElementsByClassName("text-overlay")[i].classList.add("d_none");
      document.getElementsByClassName("icons")[i].classList.add("d_none");
    },
  },
  computed: {},
  mounted() { },
};
</script>

<style scoped>
.community img:hover {
  opacity: 0.8;
  display: block !important;
}

/* .community {
  background-color: #0f0f0f;
} */
.text-overlay {
  top: 30%;
  color: #dadada;
  background-color: #061727e7;
  padding: 16px;
  padding-right: 0;
  width: 80%;
}

.sec-2 {
  top: 20%;
}

.sec-3 {
  top: 45%;
}

.icons {
  bottom: 30px;
  right: 35px;
}

.pointer {
  cursor: pointer;
}

.d_none {
  display: none;
}

.modal-dialog {
  max-width: 42% !important;
}

.modal-content {
  padding-bottom: 70px;
  padding-top: 20px;
}

.btn-holder input {
  background-color: #fafafa;
  border: 1px solid black;
  font-size: 14px;
}

.modal-body a:hover {
  color: rgba(0, 0, 0, 0.7) !important;
}

.bg-black:hover {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.card-text {
  font-size: 13px !important;
}

.custom_border_radius {
  border-radius: 20px;
}
</style>
